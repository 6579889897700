import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../Assets/camera.json'
const About = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className='bg_dog'>
            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="lg:w-4/5 mx-auto flex flex-wrap">
                        <Lottie options={defaultOptions}
                            class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
                        // height={400}
                        // width={400}
                        />
                        {/* <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="https://dummyimage.com/400x400" /> */}
                        <div class=" w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                            <h1 class="text-gray-900 text-3xl text-center md:text-[60px] mb-6 title-font font-medium">The Catcher in the Rye</h1>

                            <p class="leading-relaxed text-[30px] text-[#A7603E]">Fam locavore kickstarter distillery. Mixtape chillwave tumeric sriracha taximy chia microdosing tilde DIY. XOXO fam indxgo juiceramps cornhole raw denim forage brooklyn. Everyday carry +1 seitan poutine tumeric. Gastropub blue bottle austin listicle pour-over, neutra jean shorts keytar banjo tattooed umami cardigan.</p>


                        </div>
                       
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About